export { H1 } from './H1';
export { H2 } from './H2';
export { H3 } from './H3';
export { H4 } from './H4';
export { H5 } from './H5';
export { H6 } from './H6';
export { TextXS } from './TextXS';
export { TextSm } from './TextSm';
export { Text } from './Text';
export { TextLg } from './TextLg';
export { TextXL } from './TextXL';
export { TextMono } from './TextMono';
export { Button } from './Button';